<template>

  <div class=" bg-section-profile">
    <div class="section pt-3">
      <div class="profile-head justify-content-end mb-3">
        <div class="in">
          <h3 class="subtext">Hai,</h3>
          <h5 class="name">{{currentUser.name}}</h5>
        </div>
        <div class="avatar">
          <img class="imaged w64 rounded" :src="currentUser.picture" alt="avatar">
        </div>
      </div>
    </div>

    <div class="section mb-2">
      <div class="card rounded-4 text-center">
        <ul id="scroller">
          <li class="menu-bar" v-if="sulung">
            <router-link to="/sulung" class="item-app btn-icon">
              <img src="img/btn/sulung.png" class="button-rounded" alt=sulung>
              <span class="fw-small caption">SULUNG</span>
            </router-link>
          </li>
          <span v-for="(data, index) in this.settings.topmenu" :key="data">
            <li class="menu-bar" v-if="data.showed">
              <router-link :to="data.link" class="item-app btn-icon">
                <img :src="data.img" class="button-rounded" :alt=index>
                <span class="fw-small caption" v-html="data.label"></span>
              </router-link>
            </li>
          </span>
        </ul>
      </div>
    </div>
  </div>

  <div class="section m-1 mt-2 mb-0">
    <div class="row">
      <div class="col-6">
        <a name="" id="" class="btn btn-primary btn-block" target="_blank" href="https://units.uitm.edu.my"
          role="button">
          <img src="img/btn/aduanict.png" style="max-height: 35px; padding-right:10px">
          <div style="text-align: left;line-height: 9px;">
            <strong class="text-nowrap text-truncate">Aduan ICT</strong><br />
            <small style="font-size: 7px" class="text-nowrap text-truncate">Adukan masalah ICT anda</small>
          </div>
        </a>
      </div>
      <div class="col-6">
        <a name="" id="" class="btn btn-primary btn-block p-0" target="_blank" href="https://fms.uitm.edu.my"
          role="button">
          <img src="img/btn/aduanfasiliti.png" style="max-height: 35px; padding-right:10px">
          <div style="text-align: left;line-height: 9px;">
            <strong class="text-nowrap text-truncate">Aduan Fasiliti</strong><br />
            <small style="font-size: 7px" class="text-nowrap text-truncate">Adukan masalah fasiliti</small>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div v-if="settings.homepagevideo == ''" id="carouselExampleIndicators" class="carousel slide p-2"
    data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" v-for="(data, index) in this.settings.banner" :key="index"
        data-bs-target="#carouselExampleIndicators" :data-bs-slide-to="index" class="active" aria-current="true"
        :aria-label="data.title"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item" :class="(index == 0)?'active':''" v-for="(data, index) in this.settings.banner"
        :key="index">
        <img :src="data.url" class="d-block w-100" :alt="data.title">
        <div class="carousel-caption">
          <h3>{{ data.title }}</h3>
          <p v-html="data.description"></p>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <div v-else class="section inset m-2 col-12" v-html="settings.homepagevideo">

  </div>

  <div class="section full mb-2">
    <div class="mt-2 p-2 pt-0 pb-0">


      <div class="profile-menu menu-icon">
        <!-- <div class="col-3 d-flex justify-content-center mb-1"> -->
        <a href="https://ufuture.uitm.edu.my" target="_blank" class="item-app">
          <div class="btn-icon iconbtm">
            <img src="img/ufuture.png" alt="image" class="image w-100 h-80">
          </div>
          <span class="pt-1 fw-small text-center caption">UFuture</span>
        </a>

        <!-- </div> -->
        <!-- <div class="col-3 d-flex justify-content-center mb-1"> -->
        <a href="https://istudent.uitm.edu.my" target="_blank" class="item-app">
          <div class="btn-icon iconbtm">
            <img src="img/istudent.png" alt="image" class="imaged w-100 h-80">
          </div>
          <span class="pt-1 fw-small text-center caption">iStudent</span>
        </a>

        <a href="/masmed2u" class="item-app">
          <div class="btn-icon iconbtm">
            <img src="img/masmed2u.png" alt="image" class="imaged w-105 h-85">
          </div>
          <span class="pt-1 fw-small text-center caption">MASMED2u</span>
        </a>

        <a href="/ebook" class="item-app">
          <div class="btn-icon iconbtm">
            <img src="img/bukukoku.png" alt="image" class="imaged w-105 h-85">
          </div>
          <span class="pt-1 fw-small text-center caption">Kedai Buku</span>
        </a>

        <!-- </div> -->
        <!-- <div class="col-3 d-flex justify-content-center mb-1"> -->
        <a href="https://mykm.uitm.edu.my/v2/" target="_blank" class="item-app">
          <div class="btn-icon iconbtm">
            <img src="img/kmicon.png" alt="image" class="imaged w-100 h-80">
          </div>
          <span class="pt-1 fw-small text-center caption">PTAR MyKM</span>
        </a>

        <!-- </div> -->
        <!-- <div class="col-3 d-flex justify-content-center mb-1"> -->
        <a href="https://news.uitm.edu.my/" target="_blank" class="item-app">
          <div class="iconbtm">
            <img src="img/icon-newshub.png" alt="image" class="imaged w-100 h-80">
          </div>
          <span class="pt-1 fw-small text-center caption">NewsHub</span>
        </a>

        <!-- </div> -->
        <!-- <div class="col-3 d-flex justify-content-center mb-1"> -->
        <a href="https://mysiswa.uitm.edu.my/" target="_blank" class="item-app">
          <div class="btn-icon iconbtm">
            <img src="img/mysiswa.png" alt="image" class="imaged w-100 h-80">
          </div>
          <span class="pt-1 fw-small text-center caption">Kad MySiswa</span>
        </a>

        <!-- </div> -->
        <!-- <div class="col-3 d-flex justify-content-center mb-1"> -->
        <router-link to="/non_resident" class="item-app">
          <div class="btn-icon iconbtm">
            <img src="img/nonresident.png" alt="image" class="imaged w-100 h-80">
          </div>
          <span class="pt-1 fw-small text-center caption">Non Resident</span>
        </router-link>



        <!-- </div> -->

        <a href="https://www.wasap.my/60355442044/e-AduanHEPUiTM" target="_blank" class="item-app">
          <div class="btn-icon iconbtm">
            <img src="img/aduanHEP.png" alt="image" class="imaged w-100 h-80">
          </div>
          <span class="pt-1 fw-small text-center caption">Aduan HEP</span>
        </a>
      </div>
    </div>
  </div>
  <div id="toast-kad" class="toast-box toast-bottom bg-danger" :class="show">
    <div class="in">
      <div class="text text-white">
        Anda belum membuat persetujuan Kad MySiswa.
      </div>
    </div>
    <router-link to="/kad-prihatin" class="btn btn-sm btn-text text-white btn-outline-light">Teruskan&nbsp;<i
        class="bi bi-arrow-right-short"></i></router-link>
  </div>
  <div id="toast-kad" class="toast-box toast-bottom bg-success" :class="pickup" v-if="settings.kad_siswa == true">
    <div class="in">
      <div class="text text-white">
        Kad MySiswa anda boleh diambil. Sila lihat jadual pengambilan kad.
      </div>
    </div>
    <a :href="jadual" target="_blank" class="btn btn-sm btn-text text-white btn-outline-light">Lihat Jadual&nbsp;<i
        class="bi bi-arrow-right-short"></i></a>
  </div>
  <div id="toast-success" class="toast-box toast-bottom" :class="success">
    <div class="in">
      <ion-icon name="checkmark-circle" class="text-success"></ion-icon>
      <div class="text">
        Data telah berjaya disimpan
      </div>
    </div>
    <button type="button" class="btn btn-sm btn-text-success close-button" @click="success=''">TUTUP</button>
  </div>
  <div class="modal dialogbox" id="DialogPengumuman" data-bs-backdrop="static" tabindex="-1" aria-modal="true"
    role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Pengumuman</h5>
        </div>
        <div class="modal-body pb-0 mb-0">
          <p class="text-justify fw-lighter lh-2 text-wrap" style="text-align: justify" v-html="pengumuman"></p>
        </div>
        <div class="modal-footer pt-0 mt-0">
          <div class="btn-list">
            <a :href="pautan" target="_blank" class="btn btn-text-primary btn-block" v-if="pautannew">Lihat Pautan</a>
            <a :href="pautan" class="btn btn-text-primary btn-block" v-else>Lihat Pautan</a>
            <a href="#" class="btn btn-text-secondary btn-block" data-bs-dismiss="modal" @click="tutup"
              id="myInput">Tutup</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal dialogbox" id="DialogSponsor" data-bs-backdrop="static" tabindex="-1" aria-modal="true"
    role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Perhatian</h5>
        </div>
        <div class="modal-body pb-0 mb-0">
          <p class="text-justify fw-lighter lh-2 text-wrap" style="text-align: justify">Sila pastikan maklumat berikut
            dilengkapkan untuk meneruskan ke menu lain.</p>

          <ol class="text-left fw-lighter lh-2 text-wrap">
            <li v-for="(msg, index) in blocking_msg" v-bind:key="index">
              <span v-if="msg.title == 'Gambar'" style="font-size: 15px;">Tiada maklumat gambar. Sila kemaskini di <a
                  href="https://kad.uitm.edu.my" target="_blank">sini</a></span>
              <span v-else-if="msg.title == 'Keluarga'" style="font-size: 15px;">Belum mengemas kini maklumat pendapatan
                keluarga dan tajaan/pembiayaan/pinjaman. Sila kemaskini di <a href="/sponsor">sini</a></span>
              <span v-else style="font-size: 15px;" v-html="msg.remarks"></span>

            </li>
          </ol>
        </div>
        <!-- <div class="modal-footer pt-0 mt-0">
                      <div class="btn-list">
                                <a href="/sponsor" class="btn btn-text-primary btn-block">Kemaskini Maklumat Keluarga</a>
                            </div>
                    </div> -->
      </div>
    </div>
  </div>

</template>
<script>
import { Modal } from 'bootstrap'
import UserService from "../services/user.service";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/index";
import VueCookies from 'vue-cookies'
//import api from '@/myhep/api';
export default {
  name: 'HomePage',
  data () {
      return {
        show: '',
        pickup: '',
        success: '',
        settings: '',
        jadual: '',
        pengumuman: '',
        pautan:'',
        sponsor:'',
        blocking:null,
        blocking_msg: [],
        pendapatan: {},
        pautannew: true,
        sulung:false,
        
      }
    },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  created() {

    var success = new URL(location.href).searchParams.get('success') ;
    
    if(success == 'true'){
      this.success = 'show'
    }
  },
  methods: {
    tutup () {
      let myModal = new Modal(document.getElementById('DialogPengumuman'));
      myModal.hide();
      VueCookies.set('papar', 'false', "1d")
    }
  },
  async mounted() {

    /*await api.get('/user').then((response) => {
      console.log(response);
    },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );*/

    
    
    
    await UserService.getPublicContent().then(
      (response) => {
        this.settings = response;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    if (this.settings.pengumuman != '' && !VueCookies.isKey('papar')) {
      let myModal = new Modal(document.getElementById('DialogPengumuman'));
      myModal.show();
      this.pengumuman = this.settings.pengumuman
      this.pautan = this.settings.pautan
    }

    let docSnap = await getDoc(doc(db, "sulong", this.$store.state.auth.user.studentid));
    
    if (docSnap.exists()) {
      if(docSnap.data().accept == false){
        let myModal = new Modal(document.getElementById('DialogPengumuman'));
        myModal.show();
        this.pengumuman = '<p>السلام عليكم ورحمة الله وبركاته & Salam Sejahtera<br>Saudara/Saudari Pelajar</p><p><strong>TAWARAN MENGIKUTI PROGRAM PEMBANGUNAN SISWA SULUNG 3.0 SESI 2024 / 2025</strong></p><p>Tahniah! Sukacita saudara / saudari pelajar telah terpilih sebagai penerima manfaat Program Pembangunan Siswa Sulung 3.0 bagi sesi 2024 / 2025. Klik pada butang Lihat Pautan dibawah untuk maklumat lengkap tawaran.</p>'
        this.pautan = '/sulung'
        this.pautannew = false

      }
       
      this.sulung = true
    } 

    let updatePendapatan = false;
    this.user = await getDoc(
      doc(db, "maklumatPendapatanPelajar", `${this.$store.state.auth.user.studentid}`)
    ).then(
      user => {
        this.pendapatan = user.data()
        if(this.pendapatan == null && this.sulung == false){
          updatePendapatan = true;
          
        }else{
          if(this.pendapatan[this.settings.semester] == null && this.sulung == false)
          updatePendapatan = true;
        }
        
      });

    

    if ( (this.settings.sponsor == true && updatePendapatan == true)) {
      if(updatePendapatan == true){
        if(this.blocking_msg != null)
        this.blocking_msg.push({'title':'Keluarga','status':true})
        else
        this.blocking_msg =[{ 'title': 'Keluarga', 'status': true }]
      }
      
      let myModal = new Modal(document.getElementById('DialogSponsor'));
      myModal.show();
    }
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    
  }
};
</script>


<style lang="scss" scoped>
/* width */
::-webkit-scrollbar {
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbe2ff; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}
#scroller {
    list-style: none;
    padding: 0;
    margin: 0 7px;
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;
}
  #scroller li {
    display: inline-block;
    vertical-align: top; /* Adjust if multi-line text */
    margin: 1vw auto 3.5vw auto;
    padding-bottom: 2vh;
    width: 80px;
    color: #624bff;
    text-align: center;
    text-decoration: none;
  }
.imaged.w64 {
    /* width: 64px !important; */
    height: 3.5rem!important;
    width: 3.5rem!important;
}
.button-rounded{
    max-width: 2.7rem; margin-bottom:3px;padding: 3px;border-radius: 70%; background-color: #cbe2ff;-webkit-text-shadow:inset 1px 1px 1px black;
}

.btn-primary {
    background: #34547A !important;
    border-color: #34547A !important;
    color: #ffffff !important;
}

.card.rounded-3 {
    background-color: #F3FCFF;
    padding: 4px 2px;
}

.card .card-title {
    font-size: 0.85rem;
}

.main-box .subtitle, .middle-column .subtitle {
    font-size: 1.15rem;
    font-weight: 500;
    color: #ffffff;
}

.middle-column .title {
    font-size: 2.125rem;
    font-weight: 700;
    color: #ffffff;
}

.middle-column .subtitle {
    font-size: 0.85rem;
    font-weight: 500;
    color: #ffffff;
}

.main-box .title {
    color: #ffffff;
}

a.bg-primary, a.bg-primary:hover, a.bg-primary:active {
    background: #002E6E !important;
}

.main-box h1, .main-box h4 {
    margin: 0px 3px 8px 5px;
}

a.bg-warning {
    color: #141515 !important;
}

.profile-menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    // align-items: center;
    // padding: 0 20px;
    // margin-right: 40px;
}

.profile-menu .btn-icon {
    display: flex;
    width: 60px;
    height: 60px;
    align-items: center;
    border-radius: 200px;
    background-color: #CBE2FF;
    color: #003366;
}

.iconbtm > img {
    max-width: 65px;
}

.item-app {
    margin: 5px 8px;
}

.item-app .caption {
    font-size: 11px;
}

.bg-yellow-12 {
  background: #fff500;
  /* #ff0 */
}

.text-red {
  color: #f44336;
}

.text-bold {
  font-weight: 700;
}

.blink_me {
  animation: blinker 1s linear infinite;
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: 0.00714em;
  margin: 0 0 16px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink-column {
  padding: 15px 7px 5px 0px;
  text-align: center;
  margin-right: 7px;
}
</style>